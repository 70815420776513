import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';

import { getShopOrders } from '../../../../actions/shopActions';

import CommandRow from './commandRow';
import NoCommand from './noCommand';

import './list.styl';

class OrdersList extends Component {
  state = {
    orders: [],
  };

  componentDidMount() {
    const { getShopOrders, token } = this.props;

    getShopOrders(token).then(orders => {
      if (orders !== false) this.setState({ orders });
    });
  }

  render() {
    const { orders } = this.state;
    const { wordings } = this.props;

    // const isOrderFilled = Object.keys(orders).length;
    const commandListBody = !!orders.length ? (
      orders.map((order, commandIndex) => (
        <CommandRow order={order} key={commandIndex} wordings={wordings} />
      ))
    ) : (
      <NoCommand wordings={wordings.orders} />
    );

    return <div className="history-wrapper">{commandListBody}</div>;
  }
}

OrdersList.defaultProps = {
  profileErrors: {},
};

OrdersList.propTypes = {
  token: PropTypes.string.isRequired,
  wordings: PropTypes.object.isRequired,
  getShopOrders: PropTypes.func.isRequired,
};

const mapStateToProps = ({ account: { access_token } }) => ({
  token: access_token,
});

export default connect(
  mapStateToProps,
  { getShopOrders }
)(OrdersList);
