import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';

import {
  NavBar,
  Menu,
  Footer,
  Basket,
  pageWithNavigation,
  AccountNav,
} from '../../../components/common';

import Layout from '../../../components/layout/layout';

import OrdersList from '../../../components/account/orders/list/ordersList';

class OrdersPage extends Component {
  render() {
    const {
      data: {
        wordings: {
          fr: { orders, profile, date },
        },
      },
    } = this.props;
    const orderWordings = {
      orders,
      date,
    };
    return (
      <Layout title={orders.page_title} description={orders.description}>
        <NavBar withLogo basketClassName="yellow" />
        <Basket />
        <Menu />
        <AccountNav wordings={profile} active="account_orders">
          <OrdersList wordings={orderWordings} />
        </AccountNav>
        <Footer />
      </Layout>
    );
  }
}

OrdersPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query OrdersPageQuery {
    wordings {
      fr {
        orders {
          page_title
          description
          list_follow_shipping
          list_details
          list_prefix_command_number
          list_prefix_date
          list_no_command
          delivery_status_PENDING
          delivery_status_PROCESSING
          delivery_status_PAYMENT_PENDING
          delivery_status_PAYMENT_DUE
          delivery_status_PAID
          delivery_status_PREPPING
          delivery_status_SHIPPED
          delivery_status_COMPLETED
        }
        profile {
          profile_link
          orders_link
        }
        date {
          month_january
          month_february
          month_march
          month_april
          month_may
          month_june
          month_july
          month_august
          month_september
          month_october
          month_november
          month_december
          day_monday
          day_tuesday
          day_wednesday
          day_thursday
          day_friday
          day_saturday
          day_sunday
        }
      }
    }
  }
`;

export default pageWithNavigation(OrdersPage);
