import React from 'react';

export const formatProducts = (order, Product, rest) => {
  return order.cart.map((p, productIndex) => {
    const products = new Array(p.quantity);
    for (let i = 0; i < p.quantity; i++) {
      const key = `${i}-${order.uuid}`;
      products.push(
        <Product
          key={key}
          showInfos={i === 0 && productIndex === 0}
          item={p}
          {...rest}
        />
      );
    }
    return products;
  });
};
