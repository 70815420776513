import React from 'react';
import PropTypes from 'prop-types';

const NoCommand = ({ wordings }) => (
  <div className="no-command-wrapper">
    <div className="no-command-text">
      <span>{wordings.list_no_command}</span>
    </div>
    <div className="separator" />
  </div>
);

NoCommand.PropTypes = {
  wordings: PropTypes.object.isRequired,
};

export default NoCommand;
