import React from 'react';
import PropTypes from 'prop-types';

import { formatPrices, formatDate } from '../../../../utils/stringOperations';

const HistoryProduct = ({ showInfos, item, created, wordings, orderId }) => {
  const { date: dateWordings, orders: orderWordings } = wordings;

  const date = formatDate(created * 1000);
  const urlProduct = item.files
    .map(p => p.type === 'main' && p.url)
    .filter(url => url);
  //TODO: pass currency if another is supported
  const priceFormatted = formatPrices(item.price);

  return (
    <div className="product">
      <img className="product-image" src={urlProduct} alt="product" />
      <div className="product-infos">
        <div className="product-sub-infos">
          {showInfos && (
            <>
              <span className="command-number">
                {orderWordings.list_prefix_command_number}: #{orderId}
              </span>
              <span className="command-date">
                {orderWordings.list_prefix_date} {date.day}{' '}
                {dateWordings[date.month]} {date.year}
              </span>
            </>
          )}
          <span className="product-title">{item.name}</span>
        </div>
        <span className="product-unit-price">{priceFormatted}</span>
      </div>
    </div>
  );
};

HistoryProduct.propTypes = {
  item: PropTypes.object.isRequired,
  created: PropTypes.number.isRequired,
  showInfos: PropTypes.bool.isRequired,
  wordings: PropTypes.object.isRequired,
  orderId: PropTypes.string.isRequired,
};

export default HistoryProduct;
