import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { formatProducts } from '../shared/formatProducts';

import HistoryProduct from './historyProduct';

class CommandRow extends Component {
  render() {
    const { wordings, order } = this.props;

    const rest = {
      orderId: order.uuid.slice(0, 8),
      created: order.created_at,
      wordings,
    };
    const listProducts = formatProducts(order, HistoryProduct, rest);
    const status = `delivery_status_${order.status}`;

    return (
      <div className="command-row">
        <div className="products-wrapper">{listProducts}</div>
        {/* {this.renderProductRows(order)} */}
        <div className="command-infos-wrapper">
          <span className="command-status">{wordings.orders[status]}</span>
          <Link
            to={`/account/orders/delivery?cmdId=${order.uuid}`}
            className="command-infos-links"
          >
            {wordings.orders.list_follow_shipping}
          </Link>
          <Link
            to={`/account/orders/details?cmdId=${order.uuid}`}
            className="command-infos-links"
          >
            {wordings.orders.list_details}
          </Link>
        </div>
      </div>
    );
  }
}

CommandRow.propTypes = {
  order: PropTypes.object.isRequired,
  wordings: PropTypes.object.isRequired,
};

export default CommandRow;
